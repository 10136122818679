import Swiper from 'swiper';
import {Navigation, Autoplay, Scrollbar} from 'swiper/modules';
Swiper.use([ Navigation, Autoplay, Scrollbar]);

const imageSwiper = new Swiper('.image-swiper', {
  loop: false,
  autoplay: true,
  slidesPerView: 1,
  breakpoints: {
    450: {
      slidesPerView: 2
    },
    800: {
      slidesPerView: 3
    },
    1300: {
      slidesPerView: 4
    }
  },
});
